<div class="d-flex flex-column ml-4">
  <ng-container *ngFor="let metric of donuts() | async; trackBy: identify">
    <ds-metric-loader
      class="d-inline-block mr-1"
      [metric]="metric"
      [isListElement]="true"
      [hideLabel]="true"
    ></ds-metric-loader>
  </ng-container>
</div>
