import { Component, OnInit } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { EPerson } from '../../../core/eperson/models/eperson.model';
import { AppState } from '../../../app.reducer';
import { isAuthenticationLoading } from '../../../core/auth/selectors';
import { MYDSPACE_ROUTE } from '../../../my-dspace-page/my-dspace-page.component';
import { AuthService } from '../../../core/auth/auth.service';
import { getProfileModuleRoute, getSubscriptionsModuleRoute } from '../../../app-routing-paths';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isNotEmpty } from '../../empty.util';
import { ResearcherProfileService } from '../../../core/profile/researcher-profile.service';
import { ResearcherProfile } from '../../../core/profile/model/researcher-profile.model';
import { getFirstSucceededRemoteDataPayload } from '../../../core/shared/operators';
import { ConfigurationDataService } from '../../../core/data/configuration-data.service';
import { ItemDataService } from '../../../core/data/item-data.service';
import { Item } from '../../../core/shared/item.model';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';

/**
 * This component represents the user nav menu.
 */
@Component({
  selector: 'ds-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  /**
   * True if the authentication is loading.
   * @type {Observable<boolean>}
   */
  public loading$: Observable<boolean>;

  /**
   * The authenticated user.
   * @type {Observable<EPerson>}
   */
  public user$: Observable<EPerson>;

  /**
   * The mydspace page route.
   * @type {string}
   */
  public mydspaceRoute = MYDSPACE_ROUTE;

  /**
   * The profile page route
   */
  public profileRoute = getProfileModuleRoute();

  /**
   * The profile page route
   */
  public subscriptionsRoute = getSubscriptionsModuleRoute();

  /**
   * The URL of the HSG profile page, which is defined if item has the metadata stgallen.hsg.entityId
   */
  public hsgProfileUrl$: Observable<string>;

  constructor(private store: Store<AppState>,
              private researcherProfileService: ResearcherProfileService,
              private configurationService: ConfigurationDataService,
              private itemService: ItemDataService,
              private authService: AuthService) {
  }

  /**
   * Initialize all instance variables
   */
  ngOnInit(): void {

    // set loading
    this.loading$ = this.store.pipe(select(isAuthenticationLoading));

    // set user
    this.user$ = this.authService.getAuthenticatedUserFromStore();

    this.hsgProfileUrl$ = this.hsgProfileUrl();

  }

  hsgProfileUrl(): Observable<string> {

    const hsgProfileId$ = this.user$.pipe(
      take(1),
      switchMap((user: EPerson) => this.researcherProfileService.findById(user.id)),
      take(1),
      filter((researcherProfile: ResearcherProfile) => isNotEmpty(researcherProfile)),
      switchMap((researcherProfile: ResearcherProfile) => this.researcherProfileService.findRelatedItemId(researcherProfile)),
      switchMap((itemId: string) => this.itemService.findById(itemId)),
      getFirstSucceededRemoteDataPayload(),
      map((userDSO: Item) => userDSO.firstMetadataValue('stgallen.hsg.entityId')),
      filter((entityId: string) => isNotNullOrUndefined(entityId)),
    );

    const hsgProfileManagerUrl$: Observable<string> = this.configurationService.findByPropertyName('stgallen.profile-manager-url').pipe(
      getFirstSucceededRemoteDataPayload(),
      take(1),
      map((payload) => payload?.values[0]),
      tap((res) => {
        console.log('hsgProfileManagerUrl$ = ' + res);
      }),
    );

    return combineLatest([hsgProfileManagerUrl$, hsgProfileId$]).pipe(
      map(([profileManagerUrl, profileId]: [string, string]) => `${profileManagerUrl}${profileId}`)
    );

  }

}
